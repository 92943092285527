.navbar {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
}

.nav-item {
    padding-right: 20px;

}

.nav-item .btn {
    color: black;
}

.accordion {
    margin-top: 110px;
    margin-left: 50px;
    margin-right: 50px;
}

.accordion .btn {
    font-weight: bold;
}

.footer {
    margin-top: 50px;
    background-color: #0a052d;
    color: white;
    font-size: 14px;
    text-align: center;
    padding-bottom: 50px;
  }
  
  .footer .text {
    padding-top: 40px;
  }