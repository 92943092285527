.login-form{
    max-width: 400px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    padding: 40px 40px;
}

.login-form .logo {
    width:100% !important;
}

.login-form .btn {
    width: 200px;
}

.login-form .form-control,
.login-form .btn {
    border-radius: 40px;
    text-align: center;
}