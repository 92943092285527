.video {
    width: 251px;
    height: 230px;
    margin-right: 40px;
}

.card-text {
    text-align: center;
    font-size: 18px;
}

