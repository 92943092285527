.navbar {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
}

.nav-item {
    padding-right: 10px;
    margin-left: -10px;
}


.navbar .youtube .btn {
    color: #fff;
    background: #e60707;
    margin-left: 0px;
}

.cover {
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../assets/finalcover.jpg);
  background-size: cover;
  width: 100vw;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  text-align: center;
}

.cover .body {
  font-size: 35px;
  font-weight: bold;
}

.cover .title {
  font-size: 40px;
  font-weight: bold;
  color: #028ec7;
}

.cover .logo {
  padding-top: 50px;
}

/* .about {
    padding-top: 20px;
} */

.about-img {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../assets/about_2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 500px;
  color: white;
  text-align: center;
}

.about-img .quote {
  font-weight: bold;
  font-size: 30px;
}

.about .body {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  text-align: justify;
  background-color: lightsteelblue;
}

.about_1-img {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../assets/about.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 500px;
  color: white;
  text-align: center;
}

.about_1-img .quote {
  font-weight: bold;
  font-size: 30px;
}

.about_1 .body {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  text-align: justify;
  background-color: lightsteelblue;
}

.title-virtual {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 40px;
}

.topic {
  flex: 0.5;
  text-align: right;
  padding-right: 20px;
  padding-bottom: 20px;
}

.details {
  flex: 0.5;
  font-size: 18px;
}

.download {
  text-align: center;
}

.join {
  text-align: center;
}

/* .join .btn {
    padding-top: 20px;
} */

.img-online {
  height: auto;
  width: 400px;
  padding-bottom: 20px;
}

.slider {
  margin-top: 50px;
}

.learn-you {
  margin-top: 50px;
  background: linear-gradient(rgba(7, 1, 31, 0.8), rgba(7, 1, 31, 0.8)),
    url("../../assets//bg-1.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.learn-you .col-md-4 {
  text-align: center;
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 50px;
}

.learn-you .col-md-8 {
  font-size: 20px;
  /* font-weight: bold; */
  color: white;
  text-align: center;
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 50px;
  padding-bottom: 30px;
  line-height: 1.8;
}

.learn-you .btn {
  margin-bottom: 50px;
}

.footer {
  margin-top: 50px;
  background-color: #0a052d;
  color: white;
  font-size: 14px;
  text-align: center;
}

.footer .text {
  padding-top: 40px;
}

.social-container {
  padding: 15px 0 40px;
}

a.social:hover {
  transform: translateY(-2px);
}

.social {
  color: white;
  padding-right: 25px;
}
