

  .menu-item {
    padding: 0 40px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
  }

  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }

  .arrow {
    font-weight: bolder;
    font-size: 20px;
    background-color: darkgray;
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }